'use client'

import localFont from 'next/font/local'
import { Theme } from '@radix-ui/themes'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import '@radix-ui/themes/styles.css'
import '@/app/globals.css'
import {Providers} from "./Providers";
import SessionGuard from "@/components/SessionGuard";
import ClientSessionProvider from '@/components/shared/ClientSessionProvider'

const queryClient = new QueryClient()

if (process.env.NEXT_PUBLIC_ENABLE_LOCAL_DEBUGGING)
  console.log("ENABLE_LOCAL_DEBUGGING: ", process.env.NEXT_PUBLIC_ENABLE_LOCAL_DEBUGGING)

//console.log('process.env: ', process.env);

export default function RootLayout({ children }) {
  return (
    <html lang="en">
      <body>
        <QueryClientProvider client={queryClient}>
        <Providers>
          <SessionGuard>
            <Theme> {children} </Theme>
          </SessionGuard>
        </Providers>
          {/*
            <ReactQueryDevtools initialIsOpen={false} />
          */}
        </QueryClientProvider>
      </body>
    </html>
  )
}
